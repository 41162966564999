import urlJoin from 'url-join';
import { ogUrl, siteUrl } from './static';


const logo = urlJoin(siteUrl, '/favicon/apple-icon-120x120.png');

export const genOrganization = () => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: siteUrl,
    logo,
});


export const genDefaultJsonLd = () => ([
    genOrganization(),
]);

export const genBreadCrumb = (itemList) => {
    const itemListElement = [];
    itemList.forEach((item, index) => {
        itemListElement.push({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': item.url,
                name: item.name,
            },
        });
    });
    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
    };
};
